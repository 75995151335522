<template>
    <section class="a-propos">
        <div class="position-relative">
            <img id="polygone" src="../../../assets/images/import/cpts/polygone.png" alt="">
            <img id="polygone2" src="../../../assets/images/import/cpts/polygone2.png" alt="">
            <img id="polygone3" src="../../../assets/images/import/cpts/polygone1.png" alt="">
            <div class="container p-5">
                <h3 class="lato">Nous faisons le lien entre <span class="color-secondary">les professionnels de santé</span> et les <span class="color-secondary">patients</span>  de l'ouest lyonnais.</h3>
                <ul class="color-dot noto">
                    <li >Des services dédiés aux professionnels de santé </li>
                    <li >Des services dédiés aux patients</li>
                    <li >Des infos pratiques</li>
                    <li >Les actus santé du territoire national et local </li>
                    <li >Répertoire des associations de santé du territoire</li>
                    <li >Liste des évènements relatifs à la santé sur le territoire</li>
                </ul>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name:"apropos"
}
</script>
<style lang="scss">
ul{
    list-style: none;
}

// si mobile
@media screen and (max-width: 992px) {
    .color-dot{
        margin-left:0px;
    }    
}
//   si ordinateur
@media screen and (min-width: 992px) {
      .color-dot{
          margin-left:17rem;
      }    
  }

.color-dot {
    li{
        margin-bottom: 1rem;
    }
    li::before{
        content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: #40B78F; /* Change the color */
        font-weight: bold; /* If you want it to be bold */
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1em; /* Also needed for space (tweak if needed) */
        margin-left: -1em; /* Also needed for space (tweak if needed) */
    }
    li:nth-child(even)::before {
        color:#23B2C2
    }
}

// image sur la section a propos
#polygone{
    position:absolute;
    top:0px;
    left: 0px;
    width:15%;   
    animation: movebounce 5s linear infinite;
}
#polygone2{
    position:absolute;
    top: -6rem;
    right:2rem;
    animation: movebounce 3s linear infinite;
}
#polygone3{
    position:absolute;
    bottom: 0px;
    right:2rem;
    animation: movebounce 4s linear infinite;
}
@keyframes movebounce {
  0% {
    transform: translateY(0px); }
  50% {
    transform: translateY(20px); }
  100% {
    transform: translateY(0px); } }
</style>