<template>
    <section class="accueil">
        <div class="position-relative">
            <!-- menu lateral -->
            <menu-widget></menu-widget>
            <!-- img -->
            <img id="cover-image" class="position-relative" style="z-index:20" src="@/assets/images/import/cpts/couverture-2.png" alt="">
        </div>

        <!-- A propos -->
        <apropos data-aos="fade-up" data-aos-duration="3000"/>
        <hr>

        <!-- Connexion -->
        <div class="connexion" data-aos="fade-up" data-aos-duration="3000">
            <div class="container text-center py-5" style="color:white">
                <h3>Vous êtes un PARTICULIER</h3>
                <p>Connectez-vous à votre espace pour avoir accès à tous les services à distance !</p>
    
                <div class="m-md-5 m-sm-0">
                    <div class="container">
                        <carouselSlick/>
                    </div>
                </div>
            <div class="d-flex flex-column align-items-center justify-content-center">
                <button class="btn btn-secondary my-2">Se connecter</button>
                <label for="">Vous n'avez pas de compte? Inscrivez-vous ici</label>
            </div>
            </div>
        </div>

        <!-- map -->
        <div class="container text-center my-5" data-aos="fade-up" data-aos-duration="3000">
            <h1 class="mt-5">Professionnel de santé sur votre territoire</h1>
            <label class="text-muted lato">Tassin la demi-lune, Charbonnières-les-bains, La Tour de Salvagny, Dommartin, Limonest, 
                Champagne aux Monts d'or, Saint-Cyr aux Monts d'or, Saint-Didier aux Monts d'or</label>
            <div class="map">
                <img class="w-100" src="@/assets/images/import/cpts/map.png" alt="">
            </div>
            <button class="btn btn-primary mt-2">Rechercher un professionel de santé</button>
        </div>
        <hr>

        <!-- VOUS ETES UN PROFESSIONEL -->
        <div class="container professionel" data-aos="fade-up" data-aos-duration="3000">
            <img src="../../assets/images/import/cpts/perso.png" alt="">
            <div class="w-100 p-5 position-relative" style="border:3px solid #5CC5CC; background:white; z-index:20">
                <h3>Vous êtes un PROFESSIONNEL DE SANTÉ ?</h3>
                <label for="">Facilitez votre travail en accédant à de nombreux services !</label>
                <ul class="color-dot text-center">
                    <li v-for="item of 6" :key="item.id">Des services dédiés aux professionnels de santé </li>
                </ul>
                <button class="btn btn-primary mb-3">Se connecter</button>
                <div class="text-muted" for="">Vous n'avez pas de compte ? Inscrivez-vous ici</div>
            </div>
        </div>

        <!-- Le projet de la CPTS -->
        <projet data-aos="fade-up" data-aos-duration="3000"/>

        <!-- card-type-2/ -->
        <communaute data-aos="fade-up" data-aos-duration="3000" />
        

    </section>
</template>
<script>
import carouselSlick from '../../components/element/carouselSlick.vue'
import apropos from '../../components/page/accueil/apropos.vue'
import communaute from '../../components/page/accueil/communaute.vue'
import menuWidget from '../../components/page/accueil/menu-widget.vue'
import projet from '../../components/page/accueil/projet.vue'
// composants accueil
export default {
    name:"accueil",
    components:{
        carouselSlick,
        apropos,
        communaute,
        menuWidget,
        projet
    }
}
</script>
<style lang="scss" scoped>
//   si ordinateur
@media screen and (min-width: 992px){
    #cover-image{
        width:100%
    }
}
// mobile
@media screen and (max-width: 992px){
    #cover-image{
        height:50vh
    }
}

ul{
    list-style: none;
}
.color-dot li::before{
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #40B78F; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
}
.color-dot li:nth-child(even)::before {
    color:#23B2C2
}

.connexion{
    background: url('../../assets/images/import/cpts/fond-1.png');
    background-repeat: no-repeat;
    background-size: cover;
}

// professionel
.professionel{
    color:#AFAFAF;
    img{
        position:absolute;
        right:0;
        z-index:2;
    }
}


</style>