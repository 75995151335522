<template>
    <section class="projet">
        <div class="cpts-project">
            <div class="container text-center my-5 py-5" style="position:relative;z-index:20">
                <h3>Le projet de santé de la CPTS de **************** vise à améliorer la prise en charge de la santé de la population du territoire en renforçant la coordination interprofessionnelle, et en développant les échanges entre les professionnels de santé libéraux et les acteurs des secteurs médico-social et social.</h3>    
                <div class="card-type-2">
                    <div class="card card-rounded-1">
                        <div class="card-body d-flex flex-column justify-content-center align-items-center text-center">
                            <!-- <i class="fa fa-users"></i> -->
                            <img src="@/assets/images/import/cpts/vie_associative.png" alt="">
                            <p class="mt-4">Association loi 1901 créée en xxxx/xxxx/xxxx </p>
                        </div>
                    </div>
                    <div class="card card-rounded-2">
                        <div class="card-body d-flex flex-column justify-content-center align-items-center text-center">
                            <img src="@/assets/images/import/cpts/heart.png" alt="">
                            <p class="mt-4">Groupements de  professionnels de santé et d'acteurs des secteurs médico-social et social</p>
                        </div>
                    </div>
                    <div class="card card-rounded-1">
                        <div class="card-body d-flex flex-column justify-content-center align-items-center text-center">
                            <img class="" src="@/assets/images/import/cpts/talk.png" alt="">
                            <p class="mt-4">Nous répondons au besoins de santé du territoire</p>
                        </div>
                    </div>
                    <div class="card card-rounded-2">
                        <div class="card-body d-flex flex-column justify-content-center align-items-center text-center">
                            <img src="@/assets/images/import/cpts/give.png" alt="">
                            <p class="mt-4">Financée par l'Assurance Maladie et l'Agence Régionale de Santé</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name:'projet'
}
</script>
<style lang="scss">
// si mobile
@media screen and (max-width: 992px) {
    .card-type-2{
        display:flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
    };
    .card-type-2 {
        .card {
            margin:0.5rem;
            .card-body{
                padding:1rem
            }
        }
    }
  }
//   si ordinateur
  @media screen and (min-width: 992px) {
    .card-type-2{
        display:flex;
        justify-content: around;
    }
    .cpts-project::before{
        content: " ";
        height: 20rem;
        width: 100%;
        background-color: #F1EFEF;
        position: absolute;
    };
    .card-type-2 {
        .card {
            margin:2rem;
        }
    }
  }

.card-type-2{
    .fa, .card-type-2 .mdi {
        color: #000;
        font-size: 4rem;
    }
    .card {
        width: 100%;
    }
    img {
        width: 5rem;
    }
}
.card-rounded-1 {
    border-radius: 20px;
    background: #69C9C6;
    color: white;
}
.card-rounded-2 {
    border: 2px solid #2A6238;
    border-radius: 20px;
    background: white;
    color: #2A6238;
}


</style>