<template>
    <div class="">
      <!-- ordinateur -->
        <VueSlickCarousel class="slick carousel mt-5" v-bind="settings" :slidesToShow="slidesToShow" >
            <div class="d-flex flex-column justify-content-center align-items-center">
              <div class="rounded-content">
                <img class="carousel-img" src="../../assets/images/import/cpts/img-1.png" alt="">
              </div>
                <label for="">Trouver un professionnel de santé</label>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <div class="rounded-content">
                <img class="carousel-img" src="../../assets/images/import/cpts/img-2.png" alt="">
              </div>
                <label for="">Télétransmission de document</label>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <div class="rounded-content">
                <img class="carousel-img" src="../../assets/images/import/cpts/img-3.png" alt="">
              </div>
              <label for="">Numéros d'urgence</label>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <div class="rounded-content">
                <img class="carousel-img" src="../../assets/images/import/cpts/img-1.png" alt="">
              </div>
                <label for="">Trouver un professionnel de santé</label>
            </div>
        </VueSlickCarousel>

    </div>
  </template>
  <script>
    import VueSlickCarousel from 'vue-slick-carousel'
    // optional style for arrows & dots
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'

    export default {
      name: 'MyComponent',
      components: { VueSlickCarousel },
      data(){
        return{

            items:[
              {

              }
            ],
            settings: {
                "dots": true,
                "arrow":true,
                "dotsClass": "slick-dots custom-dot-class",
                "edgeFriction": 0.35,
                "infinite": true,
                "speed": 500,
                "slidesToScroll": 1
            },
            // nombre de slide à afficher
            slidesToShow:3
        }
      },
      methods:{
        getViewPort(){
          let vw = document.documentElement.clientWidth
          vw >= 992 ? this.slidesToShow = 3 : this.slidesToShow = 1
        }
      },
      mounted(){
        this.getViewPort()
      }
    }
  </script>
<style lang="scss">
.rounded-content{
  border-radius:100%;
  background: white;
  display:flex !important;
  justify-content: center;
  padding:2rem;
  margin-bottom:2rem;
    img{
      max-width:  11rem;
      max-height: 11rem;
    }
}

//   si mobile
@media screen and (max-width: 992px){
.rounded-content{
  img{
    max-width: 8rem;
    max-height: 8rem;
  }
}

.slick-prev::before,.slick-next::before{
  font-size:2rem !important
}
}

.slick-prev::before{
  content:"<" !important;
  font-size:6rem;
  line-height: 0%;
}
.slick-next::before{
  content:">" !important;
  font-size:6rem;
  line-height: 0%;
}


label{
  font-family: lato, sans-serif;
  font-weight: 700;
}
</style>