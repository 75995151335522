<template>
    <section class="communaute">
        <div class="container my-5">
            <h1 class="text-center">Retrouvez toute la vie de la communauté de santé</h1>
        </div>
        <div class="card-list">
            <div class="position-relative card-image-text">
                <img class="w-100" style="top:1" src="../../../assets/images/import/cpts/img-4.png" alt="">
                <p id="p-1">Evènement</p>
            </div>
            <div class=" position-relative card-image-text">
                <img class="w-100" style="top:1" src="../../../assets/images/import/cpts/img-5.png" alt="">
                <p id="p-2">Associations de santé</p>
            </div>
            <div class="position-relative card-image-text">
                <img class="w-100" style="top:1" src="../../../assets/images/import/cpts/img-6.png" alt="">
                <p id="p-3">Actualités</p>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name:"communaute"
}
</script>
<style lang="scss">
//   si mobile
@media screen and (max-width: 992px){
    .card-list{
        display:flex;
        flex-direction: column;
        align-items:center;
        justify-content:center;
        >div{
            margin:1rem
        }
        img{
            width:100%
        };
        .card-image-text {
            p {
                font-size: 2rem
            }
        }
    };
}
//   si ordinateur
@media screen and (min-width: 992px){
    .card-list{
        display:flex;
        img{
            width:100%
        };
        >div{
            width:100%
        }
        .card-image-text:hover{
            &:before{
                content:" ";
                width:100%;
                height:100%;
                background: #fff;
                opacity: 0.8;
                position: absolute;
            }
            p{
                // color: #5FC6CA;
                font-size:1.5rem;
                position: absolute;
                top: 30%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            #p-1{
                color: #B7001E;
            }
            #p-2{
                color: #40B78F;
            }
            #p-3{
                color: #2A6238;
            }
        };
    }
}
.card-image-text{
    p{
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition:0.5s ease-out
    };
    
};

/* card image avec texte centré */
.card-image-text{
    &:before{
        content:" ";
        width:100%;
        height:100%;
        background: #707070;
        opacity: 0.5;
        position: absolute;
    };
    p{
        color:#fff
    }
    
}

</style>